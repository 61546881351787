import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import "./App.css";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Contactus from "./Components/Pages/Contactus";
import Header from "./Components/Main/Header";
import Footer from "./Components/Main/Footer";
import Products from "./Components/Pages/Dropdown/Products";
import Blog from "./Components/Pages/Blog";
import Filter from "./Components/Functions/Filter";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/Blog" component={Blog} />
          <Route path="/contactus" component={Contactus} />
          <Route path="/Products" component={Products} />
          <Route path="/Filter" component={Filter} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
