// import React from "react";
// import { ImWhatsapp } from "react-icons/im";

// import styled from "styled-components";

// const Floatbutton = () => {
//   const WhatsAppButton = styled.a`
//     position: fixed;
//     bottom: 90px;
//     right: 20px;
//     width: 60px;
//     height: 60px;
//     border-radius: 50%;
//     background-color: #25d366; /* WhatsApp green color */
//     border: none;
//     cursor: pointer;
//     z-index: 999;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #ffffff;
//     font-size: 30px; /* Adjust icon size */
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
//     text-decoration: none; /* Remove underline from link */

//     &:hover {
//       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
//       transform: translateY(-10px);
//       background-color: #ffff; /* Darker shade of green on hover */
//       color: #25d366;
//     }
//   `;

//   return (
//     <WhatsAppButton href="https://wa.me/+917010342147" target="_blank">
//       <ImWhatsapp />
//     </WhatsAppButton>
//   );
// };

// export default Floatbutton;

// import React, { useState } from "react";
// import { ImWhatsapp } from "react-icons/im";
// import styled from "styled-components";
// import { HiOutlineChatBubbleLeftEllipsis } from "react-icons/hi2";

// const Floatbutton = () => {
//   const [requestform, setRequestform] = useState(false);

//   const WhatsAppButton = styled.a`
//     position: fixed;
//     bottom: 90px;
//     right: 20px;
//     width: 60px;
//     height: 60px;
//     border-radius: 50%;
//     background-color: #25d366; /* WhatsApp green color */
//     border: none;
//     cursor: pointer;
//     z-index: 999;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #ffffff;
//     font-size: 30px; /* Adjust icon size */
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
//     text-decoration: none; /* Remove underline from link */

//     &:hover {
//       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
//       transform: translateY(-10px);
//       background-color: #ffff; /* Darker shade of green on hover */
//       color: #25d366;
//     }
//   `;

//   const ChatbotButton = styled.div`
//     position: fixed;
//     bottom: 160px;
//     right: 20px;
//     width: 60px;
//     height: 60px;
//     border-radius: 50%;
//     background-color: #0078d4; /* Example chatbot button color */
//     border: none;
//     cursor: pointer;
//     z-index: 999;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #ffffff;
//     font-size: 30px; /* Adjust icon size */
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
//     text-decoration: none; /* Remove underline from link */

//     &:hover {
//       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
//       transform: translateY(-10px);
//       background-color: #004578; /* Darker shade of blue on hover */
//       color: #ffffff;
//     }
//   `;

//   const handlerequestformToggle = () => {
//     setRequestform(!requestform);
//   };

//   return (
//     <>
//       {requestform && (
//         <div
//           class="modal fade"
//           id="exampleModal"
//           tabindex="-1"
//           role="dialog"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div class="modal-dialog" role="document">
//             <div class="modal-content">
//               <div class="modal-header">
//                 <h5 class="modal-title" id="exampleModalLabel">
//                   New message
//                 </h5>
//                 <button
//                   type="button"
//                   class="close"
//                   data-dismiss="modal"
//                   aria-label="Close"
//                   onClick={handlerequestformToggle} // Close modal on button click
//                 >
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>
//               <div class="modal-body">
//                 <form>
//                   <div class="form-group">
//                     <label for="recipient-name" class="col-form-label">
//                       Recipient:
//                     </label>
//                     <input
//                       type="text"
//                       class="form-control"
//                       id="recipient-name"
//                     />
//                   </div>
//                   <div class="form-group">
//                     <label for="message-text" class="col-form-label">
//                       Message:
//                     </label>
//                     <textarea class="form-control" id="message-text"></textarea>
//                   </div>
//                 </form>
//               </div>
//               <div class="modal-footer">
//                 <button
//                   type="button"
//                   class="btn btn-secondary"
//                   data-dismiss="modal"
//                   onClick={handlerequestformToggle} // Close modal on button click
//                 >
//                   Close
//                 </button>
//                 <button type="button" class="btn btn-primary">
//                   Send message
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <ChatbotButton onClick={handlerequestformToggle}>
//         <HiOutlineChatBubbleLeftEllipsis />
//       </ChatbotButton>

//       <WhatsAppButton href="https://wa.me/+917010342147" target="_blank">
//         <ImWhatsapp />
//       </WhatsAppButton>
//     </>
//   );
// };

// export default Floatbutton;

import React, { useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import styled from "styled-components";
import { HiOutlineChatBubbleLeftEllipsis } from "react-icons/hi2";

const Floatbutton = () => {
  const [requestform, setRequestform] = useState(false);

  const WhatsAppButton = styled.a`
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25d366; /* WhatsApp green color */
    border: none;
    cursor: pointer;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 30px; /* Adjust icon size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-decoration: none; /* Remove underline from link */

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      transform: translateY(-10px);
      background-color: #128c7e; /* Darker shade of green on hover */
    }
  `;

  const ChatbotButton = styled.div`
    position: fixed;
    bottom: 120px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0078d4; /* Example chatbot button color */
    border: none;
    cursor: pointer;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 30px; /* Adjust icon size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-decoration: none; /* Remove underline from link */

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      transform: translateY(-10px);
      background-color: #005a9e; /* Darker shade of blue on hover */
    }
  `;

  const handlerequestformToggle = () => {
    setRequestform(!requestform);
  };

  const handleCloseModal = () => {
    setRequestform(false);
  };

  return (
    <>
      <div
        className="modal "
        style={{ display: requestform ? "block" : "none" }}
        id="requestform"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Request Form</h5>
              <button
                type="button"
                className="close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Mobile Number:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message-text" className="col-form-label">
                    Message:
                  </label>
                  <textarea
                    className="form-control"
                    id="message-text"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Send message
              </button>
            </div>
          </div>
        </div>
      </div>

      <ChatbotButton onClick={handlerequestformToggle}>
        <HiOutlineChatBubbleLeftEllipsis />
      </ChatbotButton>

      <WhatsAppButton href="https://wa.me/+917010342147" target="_blank">
        <ImWhatsapp />
      </WhatsAppButton>
    </>
  );
};

export default Floatbutton;
