// import React from "react";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";

// const Header = () => {
//   return (
//     <>
//       <div
//         className="site-mobile-menu site-navbar-target"
//         data-spy="scroll"
//         data-target=".site-navbar-target"
//         data-offset="300"
//       >
//         <div className="site-mobile-menu-header">
//           <div className="site-mobile-menu-close mt-3">
//             <span className="icon-close2 js-menu-toggle"></span>
//           </div>
//         </div>
//         <div className="site-mobile-menu-body"></div>
//       </div>
//       <div className="border-bottom top-bar py-2 bg-dark" id="home-section">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6">
//               <p className="mb-0 header-navcontact">
//                 <span className="mr-3">
//                   <strong className="text-white  ">Phone:</strong>{" "}
//                   <Link to="tel://#">+1 234 5678 9101 </Link>
//                 </span>
//                 <span>
//                   <strong className="text-white ">Email:</strong>{" "}
//                   <Link to="#">info@yourdomain.com </Link>
//                 </span>
//               </p>
//             </div>
//             <div className="col-md-6">
//               <ul className="social-media header-navcontact">
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-facebook"></span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-twitter"></span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-instagram"></span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-linkedin"></span>
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       <header
//         className="site-navbar py-4 bg-white js-sticky-header site-navbar-target"
//         role="banner"
//       >
//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-11 col-xl-2">
//               <h1 className="mb-0 site-logo">
//                 <Link to="/" className="text-black h2 mb-0">
//                   Prayaag{" "}
//                 </Link>
//               </h1>
//             </div>
//             <div className="col-12 col-md-10 d-none d-xl-block">
//               <nav
//                 className="site-navigation position-relative text-right"
//                 role="navigation"
//               >
//                 <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
//                   <li>
//                     <Link to="/" className="nav-link">
//                       Home
//                     </Link>
//                   </li>

//                   <li>
//                     <Link to="/about" className="nav-link">
//                       About
//                     </Link>
//                   </li>
//                   <li className="has-children">
//                     <Link to="dropdown" className="nav-link">
//                       dropdown
//                     </Link>
//                     <ul className="dropdown">
//                       <li>
//                         <Link to="/Products">Products </Link>
//                       </li>
//                       <li>
//                         <Link to="/">Our Team </Link>
//                       </li>
//                     </ul>
//                   </li>
//                   <li>
//                     <Link to="/Blog" className="nav-link">
//                       Blog
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/contactus" className="nav-link">
//                       Contact
//                     </Link>
//                   </li>
//                 </ul>
//               </nav>
//             </div>

//             <div
//               className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
//               style={{ position: "relative", top: "3px" }}
//             >
//               <Link
//                 to="#"
//                 className="site-menu-toggle js-menu-toggle text-black"
//               >
//                 <span className="icon-menu h3"></span>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </header>
//     </>
//   );
// };

// export default Header;

// import React, { useState } from "react";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import "../Pages/Home.css";

// const Header = () => {
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [activeDropdown, setActiveDropdown] = useState(null);

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//     setActiveDropdown(null); // Reset active dropdown when closing mobile menu
//   };

//   const toggleDropdown = (index) => {
//     setActiveDropdown(activeDropdown === index ? null : index);
//   };
//   return (
//     <>
//       <div
//         className={`site-mobile-menu site-navbar-target ${
//           isMobileMenuOpen ? "active" : ""
//         }`}
//         data-spy="scroll"
//         data-target=".site-navbar-target"
//         data-offset="300"
//       >
//         <div className="site-mobile-menu-header">
//           <div
//             className="site-mobile-menu-close mt-3"
//             onClick={toggleMobileMenu}
//           >
//             <span className="icon-close2 js-menu-toggle"></span>
//           </div>
//         </div>
//         <div className="site-mobile-menu-body">
//           <ul className="site-menu main-menu js-clone-nav mr-auto d-lg-none">
//             <li>
//               <Link to="/" className="nav-link" onClick={toggleMobileMenu}>
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="/about" className="nav-link" onClick={toggleMobileMenu}>
//                 About
//               </Link>
//             </li>
//             <li
//               className={`has-children ${activeDropdown === 0 ? "active" : ""}`}
//             >
//               <Link
//                 to="#"
//                 className="nav-link"
//                 onClick={() => toggleDropdown(0)}
//               >
//                 Dropdown
//               </Link>
//               <ul
//                 className="dropdown"
//                 style={{ display: activeDropdown === 0 ? "block" : "none" }}
//               >
//                 <li>
//                   <Link to="/Products" onClick={toggleMobileMenu}>
//                     Products
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/" onClick={toggleMobileMenu}>
//                     Our Team
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <Link to="/Blog" className="nav-link" onClick={toggleMobileMenu}>
//                 Blog
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/contactus"
//                 className="nav-link"
//                 onClick={toggleMobileMenu}
//               >
//                 Contact
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//       <div className="border-bottom top-bar py-2 bg-dark" id="home-section">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6">
//               <p className="mb-0 header-navcontact">
//                 <span className="mr-3">
//                   <strong className="text-white  ">Phone:</strong>{" "}
//                   <Link to="tel://#">+1 234 5678 9101 </Link>
//                 </span>
//                 <span>
//                   <strong className="text-white ">Email:</strong>{" "}
//                   <Link to="#">info@yourdomain.com </Link>
//                 </span>
//               </p>
//             </div>
//             <div className="col-md-6">
//               <ul className="social-media header-navcontact">
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-facebook"></span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-twitter"></span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-instagram"></span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="#" className="p-2">
//                     <span className="icon-linkedin"></span>
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       <header
//         className="site-navbar py-4 bg-white js-sticky-header site-navbar-target"
//         role="banner"
//       >
//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-11 col-xl-2">
//               <h1 className="mb-0 site-logo">
//                 <Link to="/" className="text-black h2 mb-0">
//                   Prayaag{" "}
//                 </Link>
//               </h1>
//             </div>
//             <div className="col-12 col-md-10 d-none d-xl-block">
//               <nav
//                 className="site-navigation position-relative text-right"
//                 role="navigation"
//               >
//                 <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
//                   <li>
//                     <Link to="/" className="nav-link">
//                       Home
//                     </Link>
//                   </li>

//                   <li>
//                     <Link to="/about" className="nav-link">
//                       About
//                     </Link>
//                   </li>
//                   <li className="has-children">
//                     <Link to="dropdown" className="nav-link">
//                       dropdown
//                     </Link>
//                     <ul className="dropdown">
//                       <li>
//                         <Link to="/Products">Products </Link>
//                       </li>
//                       <li>
//                         <Link to="/">Our Team </Link>
//                       </li>
//                     </ul>
//                   </li>
//                   <li>
//                     <Link to="/Blog" className="nav-link">
//                       Blog
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/contactus" className="nav-link">
//                       Contact
//                     </Link>
//                   </li>
//                 </ul>
//               </nav>
//             </div>

//             <div
//               className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
//               style={{ position: "relative", top: "3px" }}
//             >
//               <Link
//                 to="#"
//                 className="site-menu-toggle js-menu-toggle text-black"
//               >
//                 <span className="icon-menu h3"></span>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </header>
//     </>
//   );
// };

// export default Header;

import "../Pages/Home.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setActiveDropdown(null); // Reset active dropdown when closing mobile menu
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <>
      <div
        className={`site-mobile-menu site-navbar-target ${
          isMobileMenuOpen ? "active" : ""
        }`}
        data-spy="scroll"
        data-target=".site-navbar-target"
        data-offset="300"
      >
        <div className="site-mobile-menu-header">
          <div
            className="site-mobile-menu-close mt-3"
            onClick={toggleMobileMenu}
          >
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="">
          <ul className="site-menu main-menu js-clone-nav mr-auto d-lg-none mt-5">
            <li>
              <Link to="/" className="nav-link" onClick={toggleMobileMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="nav-link" onClick={toggleMobileMenu}>
                About
              </Link>
            </li>
            <li
              className={`has-children ${activeDropdown === 0 ? "active" : ""}`}
            >
              <Link
                to="#"
                className="nav-link"
                onClick={() => toggleDropdown(0)}
              >
                Dropdown
              </Link>
              <ul
                className="dropdown"
                style={{
                  display: activeDropdown === 0 ? "block" : "none",
                }}
              >
                <li>
                  <Link
                    to="/Products"
                    onClick={toggleMobileMenu}
                    className="dropdown-li"
                  >
                    Products
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={toggleMobileMenu}
                    className="dropdown-li"
                  >
                    Our Team
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/Blog" className="nav-link" onClick={toggleMobileMenu}>
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contactus"
                className="nav-link"
                onClick={toggleMobileMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-bottom top-bar py-2 bg-dark" id="home-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0 header-navcontact">
                <span className="mr-3">
                  <strong className="text-white  ">Phone:</strong>{" "}
                  <Link to="tel:+91 ">+1 234 5678 9101</Link>
                </span>
                <span>
                  <strong className="text-white ">Email:</strong>{" "}
                  <Link to="#">info@yourdomain.com </Link>
                </span>
              </p>
            </div>
            <div className="col-md-6">
              <ul className="social-media header-navcontact">
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-facebook"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-twitter"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-instagram"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-linkedin"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header
        className="site-navbar py-0 py-md-4 bg-white js-sticky-header site-navbar-target"
        role="banner"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-10 col-xl-2">
              <h1 className="mb-0 site-logo">
                <Link to="/" className="text-black h2 mb-0">
                  Prayaag
                </Link>
              </h1>
            </div>
            <div className="col-12 col-md-10 d-none d-xl-block">
              <nav
                className="site-navigation position-relative text-right"
                role="navigation"
              >
                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                  <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="has-children">
                    <Link
                      to="#"
                      className="nav-link"
                      onClick={() => toggleDropdown(0)}
                    >
                      Dropdown
                    </Link>
                    <ul
                      className="dropdown"
                      style={{
                        display: activeDropdown === 0 ? "block" : "none",
                      }}
                    >
                      <li>
                        <Link to="/Products">Products</Link>
                      </li>
                      <li>
                        <Link to="/">Our Team</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/Blog" className="nav-link">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div
              className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
              style={{ position: "relative", top: "3px" }}
            >
              <button
                className="site-menu-toggle js-menu-toggle text-black"
                onClick={toggleMobileMenu}
              >
                <span className="icon-menu h3"></span>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
