import React from "react";

const Filter = () => {
  return (
    <>
      <h1 className=" text-center" id="productpage">
        Testing
      </h1>
    </>
  );
};

export default Filter;
