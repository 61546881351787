import React from "react";
import "../Home.css";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Floatbutton from "../../Functions/Floatbutton";

const Products = () => {
  return (
    <>
      {/*-------------------------------------- filter-mobile start---------------------------------------- */}

      <div className="filter-main-mobile" id="productpage">
        <Link to="/Filter">
          <button> Filter</button>
        </Link>
        <div className="col-12 text-center ">
          <h2 className="text-black h1 site-section-heading">Product</h2>
        </div>
      </div>
      {/*-------------------------------------- filter-mobile end---------------------------------------- */}
      <div className="col-12 text-center " id="productpage-web">
        <h2 className="text-black h1 site-section-heading">
          Our Product Range
        </h2>
      </div>

      <div className="product-main">
        {/*-------------------------------------- filter-web---------------------------------------- */}

        <div className="filter-main-web">
          <h2>Filters</h2>
          {/* <div class="product-card">
            <img
              src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg"
              alt="Delicious Food"
            />
            <div class="product-card-content">
              <h2>Gourmet Burger</h2>
              <p>
                Experience the taste of gourmet burgers with this simple yet
                delicious recipe.
              </p>
            </div>
          </div> */}
        </div>

        {/*-------------------------------------- product-card---------------------------------------- */}
        <div class="product-card-container">
          <div class="product-card">
            <img
              src="https://aedstore.in/wp-content/uploads/2024/02/Heartguard-II.jpg"
              alt="Delicious Food"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
          <div class="product-card">
            <img
              src="https://aedstore.in/wp-content/uploads/2024/03/HR-1-IMAGE.jpg"
              alt="Tasty Pasta"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
          <div class="product-card">
            <img
              src="https://d3edssu4vf7j1p.cloudfront.net/images/detailed/64/6208_1__2__lyos-pj.jpg?t=1704784547"
              alt="Healthy Salad"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
          <div class="product-card">
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2023/4/299250154/EN/ED/YG/12921432/prisma-25st-bipap-with-st--1000x1000.jpg"
              alt="Tasty Pasta"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
          <div class="product-card">
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2022/4/KB/CB/XF/9476903/bioure-dermatome-mesher-catalogue-4-1000x1000.jpg"
              alt="Healthy Salad"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
          <div class="product-card">
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2024/6/427290621/BF/SR/TY/21575320/alere-triage-machine-1000x1000.jpg"
              alt="Tasty Pasta"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
          <div class="product-card">
            <img
              src="https://5.imimg.com/data5/TD/IC/DH/SELLER-1312886/scalp-cooling-machine-1000x1000.PNG"
              alt="Healthy Salad"
            />
            <div class="product-card-content">
              <h2>Testing</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptatem pariatur hic dignissimos iusto officia corporis
              </p>
            </div>
          </div>
        </div>
      </div>
      <Floatbutton />
    </>
  );
};

export default Products;
