import React, { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "../Pages/Home.css";
import { FaHeart } from "react-icons/fa";
import Floatbutton from "../Functions/Floatbutton";
const Home = () => {
  useEffect(() => {
    document.title = "Home | Prayaag";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", "Welcome to Prayaag");
  }, []);
  return (
    <div>
      <div
        className="site-blocks-cover overlay"
        // style="background-image: url(images/hero_1.jpg);"
        style={{ backgroundImage: "url(assets/images/homebanner.jpg)" }}
        data-aos="fade"
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
              <div className="row justify-content-center mb-4">
                <div className="col-md-8 text-center">
                  <h1>Welcome to Prayaag</h1>
                  {/* <p className="lead mb-5">
                    Free Web Template by{" "}
                    <Link href="#" target="_blank">
                      Colorlib
                    </Link>
                  </p> */}
                  <div>
                    <Link to="/contactus" className="btn btn-primary btn-md">
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 text-center mt-3">
        <h2 className="text-black h1 site-section-heading"> Our Product</h2>
      </div>
      <div class="product-card-container mb-5">
        <div class="product-card">
          <img
            src="https://aedstore.in/wp-content/uploads/2024/02/Heartguard-II.jpg"
            alt="Tasty Pasta"
          />
          <div class="product-card-content">
            <h2>Testing</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptatem pariatur hic dignissimos iusto officia corporis
            </p>
          </div>
        </div>
        <div class="product-card">
          <img
            src="https://aedstore.in/wp-content/uploads/2024/03/HR-1-IMAGE.jpg"
            alt="Healthy Salad"
          />
          <div class="product-card-content">
            <h2>Testing</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptatem pariatur hic dignissimos iusto officia corporis
            </p>
          </div>
        </div>
        <div class="product-card">
          <img
            src="https://5.imimg.com/data5/SELLER/Default/2024/6/427290621/BF/SR/TY/21575320/alere-triage-machine-1000x1000.jpg"
            alt="Tasty Pasta"
          />
          <div class="product-card-content">
            <h2>Testing</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptatem pariatur hic dignissimos iusto officia corporis
            </p>
          </div>
        </div>
        <div class="product-card">
          <img
            src="https://5.imimg.com/data5/TD/IC/DH/SELLER-1312886/scalp-cooling-machine-1000x1000.PNG"
            alt="Healthy Salad"
          />
          <div class="product-card-content">
            <h2>Testing</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptatem pariatur hic dignissimos iusto officia corporis
            </p>
          </div>
        </div>
      </div>
      <Floatbutton />
    </div>
  );
};

export default Home;
